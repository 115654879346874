import { render, staticRenderFns } from "./DataCXC.vue?vue&type=template&id=395db586&"
import script from "./DataCXC.vue?vue&type=script&lang=js&"
export * from "./DataCXC.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VBtnToggle,VCheckbox,VCol,VDataIterator,VDivider,VExpandTransition,VExpansionPanels,VFadeTransition,VIcon,VRow,VSkeletonLoader,VToolbar})
